import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import track from '../assets/track.svg';
import { statusOptions } from '../data';
import apiCallsService from '../services/apiCalls.service';
import { StyledSelectCampaignOverview } from '../styledComponents/form/Select';
import { Title } from '../components/Title';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Wrapper = styled.div`
	padding: 31px 25px 30px;
	& > a {
		display: block;
		max-width: 197px;
		padding: 9px 30px;
		background: #265b97;
		border-radius: 5px;
		margin: 28px 0 20px auto;
		transition: all 0.2s ease;
		& > span {
			font-weight: 400;
			font-size: 18px;
			text-align: center;
			color: #ffffff;
		}
		&:hover {
			background: #0a396b;
		}
	}
`;

const Table = styled.div`
	border: 1px solid #e4e4ef;
	border-radius: 5px;
	width: 100%;
`;

const Top = styled.div`
	display: flex;
	background: #f4f4f6;
	width: 100%;
`;

const TABLE_ROWS_WIDTH = [
	null,
	'7%',
	'22%',
	'12%',
	'12%',
	'6%',
	'27%',
	'7%',
	'7%',
];

const TopItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px 8px 10px;
	border-right: 1px solid #e4e4ef;
	&:first-child {
		width: ${TABLE_ROWS_WIDTH[1]};
	}

	&:nth-child(2) {
		width: ${TABLE_ROWS_WIDTH[2]};
	}

	&:nth-child(3) {
		width: ${TABLE_ROWS_WIDTH[3]};
	}

	&:nth-child(4) {
		width: ${TABLE_ROWS_WIDTH[4]};
	}

	&:nth-child(5) {
		width: ${TABLE_ROWS_WIDTH[5]};
	}

	&:nth-child(6) {
		width: ${TABLE_ROWS_WIDTH[6]};
	}

	&:nth-child(7) {
		width: ${TABLE_ROWS_WIDTH[8]};
		text-align: center;
		& > span {
			padding-left: 0;
		}
	}

	&:last-child {
		width: ${TABLE_ROWS_WIDTH[9]};
		border-right: none;
		text-align: center;
		& > span {
			padding-left: 0;
		}
	}

	& > span {
		font-weight: 500;
		font-size: 14px;
		color: #555555;
		padding-left: 6px;
	}
`;

const Input = styled.input`
	background: #ffffff;
	border: 1px solid #e4e4ef;
	border-radius: 5px;
	height: 25px;
	padding: 5px 10px;
`;

const Content = styled.div`
	display: flex;
	border-bottom: 1px solid #e4e4ef;

	&:last-child {
		border-bottom: none;
	}
`;

const CopyButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
`;

const Item = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #e4e4ef;
	text-align: center;
	padding: 12px 8px;

	& > span {
		font-weight: 400;
		font-size: 12px;
		letter-spacing: -0.02em;
		color: #555555;
	}
	&:first-child {
		width: ${TABLE_ROWS_WIDTH[1]};
		& > span {
			text-transform: capitalize;
		}
	}

	&:nth-child(2) {
		width: ${TABLE_ROWS_WIDTH[2]};
		cursor: pointer;
	}

	&:nth-child(3) {
		width: ${TABLE_ROWS_WIDTH[3]};
	}

	&:nth-child(4) {
		width: ${TABLE_ROWS_WIDTH[4]};
	}

	&:nth-child(5) {
		width: ${TABLE_ROWS_WIDTH[5]};
		& > span {
			text-transform: uppercase;
		}
	}

	&:nth-child(6) {
		width: ${TABLE_ROWS_WIDTH[6]};
	}

	&:nth-child(7) {
		width: ${TABLE_ROWS_WIDTH[7]};
	}

	&:last-child {
		width: ${TABLE_ROWS_WIDTH[8]};
		border-right: none;
	}
`;

const Image = styled.img`
	max-width: 14px;
	width: 100%;
`;

export const CampaignOverview = () => {
	const [campaignInfoList, setCampaignInfoList] = useState([]);
	const [filteredCampaignList, setFilteredCampaignList] = useState([]);
	const [temoraryCampaignList, setTemoraryCampaignList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [snackBarOpened, setSnackBarOpened] = React.useState(false);

	const [errorBlock, setErrorBlock] = useState(false);

	useEffect(() => {
		const createCampaignOverviewResponse = async () => {
			const response = await apiCallsService.createCampaignOverviewRequest();
			setIsLoading(false);

			if (response.error) {
				setErrorBlock(true);
				return;
			}

			setCampaignInfoList(response);
			setTemoraryCampaignList(response);
		};

		createCampaignOverviewResponse();
	}, []);

	const handleClickCopyLink = (e, link) => {
		e.preventDefault();
		navigator.clipboard.writeText(link);
		setSnackBarOpened(true);
	};

	const handleChange = statusValue => {
		const filterCampaigByStatus = temoraryCampaignList.filter(
			item => item.status.toLowerCase() === statusValue.toLowerCase()
		);

		if (
			statusValue.toLowerCase() === 'pending' ||
			statusValue.toLowerCase() === 'active'
		) {
			if (filterCampaigByStatus.length === 0) {
				setFilteredCampaignList([]);
				setCampaignInfoList([]);
				return;
			}
		}

		setFilteredCampaignList(filterCampaigByStatus);

		if (filterCampaigByStatus.length === 0) {
			setCampaignInfoList(temoraryCampaignList);
			return;
		}
	};

	const filterBySearch = (e, category) => {
		const query = e.target.value;

		const updatedCampaignList = temoraryCampaignList.filter(item => {
			if (!item[category]) {
				return false;
			}

			return item[category].toLowerCase().indexOf(query.toLowerCase()) !== -1;
		});

		if (updatedCampaignList.length === 0) {
			setFilteredCampaignList([]);
			setCampaignInfoList([]);
		} else {
			setFilteredCampaignList(updatedCampaignList);
		}
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBarOpened(false);
	};

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	return (
		<>
			<Title title='Campaign overview' />
			<Wrapper>
				<Snackbar open={snackBarOpened} autoHideDuration={6000} onClose={handleSnackBarClose}>
					<Alert onClose={handleSnackBarClose} severity="success" sx={{width: '100%'}}>
						Copied to clipboard
					</Alert>
				</Snackbar>

				{errorBlock && <MuiAlert severity="error" style={{marginBottom: '10px'}}>Failed to display campaign overview</MuiAlert>}

				<Link to='/new-campaign'>
					<span>New campaign</span>
				</Link>

				{isLoading && (
					<div className='loading-box'>
						<CircularProgress />
					</div>
				)}
				{!isLoading && !errorBlock &&  (
					<Table>
						<Top>
							<TopItem>
								<span>Status</span>
								<StyledSelectCampaignOverview
									className='basic-single'
									classNamePrefix='select'
									placeholder=''
									onChange={choice => handleChange(choice.value)}
									options={statusOptions}
								/>
							</TopItem>
							<TopItem>
								<span>Campaign name</span>
								<Input type='text' onChange={e => filterBySearch(e, 'title')} />
							</TopItem>
							<TopItem>
								<span>Offer</span>
								<Input type='text' onChange={e => filterBySearch(e, 'offer')} />
							</TopItem>
							<TopItem>
								<span>Vertical</span>
								<Input
									type='text'
									onChange={e => filterBySearch(e, 'vertical')}
								/>
							</TopItem>
							<TopItem>
								<span>Geo</span>
								<Input type='text' onChange={e => filterBySearch(e, 'geo')} />
							</TopItem>
							<TopItem>
								<span>Ad title</span>
								<Input
									type='text'
									onChange={e => filterBySearch(e, 'adTitle')}
								/>
							</TopItem>
							<TopItem>
								<span>Track</span>
							</TopItem>
							<TopItem>
								<span>Final</span>
							</TopItem>
						</Top>
						{(filteredCampaignList.length > 0
							? filteredCampaignList
							: campaignInfoList
						)?.map(item => (
							<Content key={item.ourId}>
								<Item>
									<span style={{color: item.status === 'active' && '#00B227'}}>
										{item.status}
									</span>
								</Item>
								<Item onClick={e => handleClickCopyLink(e, item.title)}>
									<span>{item.title}</span>
								</Item>
								<Item>
									<span>{item.offer}</span>
								</Item>
								<Item>
									<span>{item.vertical}</span>
								</Item>
								<Item>
									<span>{item.geo}</span>
								</Item>
								<Item>
									<span>{item.adTitle}</span>
								</Item>
								<Item onClick={e => handleClickCopyLink(e, item.track)}>
									{item.track ? (
										<CopyButton to={item.track}>
											<Image src={track} alt='track' />
										</CopyButton>
									) : null}
								</Item>
								<Item onClick={e => handleClickCopyLink(e, item.trackFull)}>
									{item.trackFull ? (
										<CopyButton to={item.trackFull}>
											<Image src={track} alt='track' />
										</CopyButton>
									) : null}
								</Item>
							</Content>
						))}
					</Table>
				)}
			</Wrapper>
		</>
	);
};
