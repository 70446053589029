import React, {useState} from "react";
import { Title } from "../../components/Title";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


import { KeywordToOfferControllableWidget } from './KeywordToOfferControllableWidget';
import { SeasonalityOffersWidget } from './SeasonalityOffersWidget';
import { TopGainingWidgets } from './TopGainingWidgets';



export const KeywordsDashboard = () => {
	const [errorText, setErrorText] = useState("");


	return (
		<>
			<Title title="Keywords dashboard" />
			{errorText && (
				<Alert severity="error" style={{ marginBottom: "10px" }}>
					{errorText}
				</Alert>
			)}

			<Box sx={{ m: 1 }}>
				<Typography variant="h4">Keywords</Typography>

				<Grid container spacing={3} sx={{mb: 2}}>
					<Grid item xs={12}>
						<KeywordToOfferControllableWidget title="Top Keywords by Revenue Relative to Offer" />
					</Grid>
				</Grid>

				<Grid container spacing={3} sx={{mb: 2}}>
					<Grid item xs={12}>
						<KeywordToOfferControllableWidget title="Worst Keywords by Revenue Relative to Offer" summaryType={'compare_to_offer_revenue_negative'} defaultLimit={10}/>
					</Grid>
				</Grid>


				<Grid container spacing={3} sx={{mb: 2}}>
					<Grid item xs={12}>
						<SeasonalityOffersWidget title="Weekly seasonality: Best for tomorrow" summaryType={'weekly_seasonality'} defaultLimit={20}/>
					</Grid>
				</Grid>


				<Grid container spacing={3} sx={{mt: 1}}>
					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<Typography variant="h5" sx={{ml: 2, mt: 1}}>
								Last 7 Days Top-Gaining Keywords
							</Typography>

							<CardContent>
								<TopGainingWidgets aggregation={'keywords'} summaryType={'stable_avg_revenue_ratio'} periodId={'7d'}/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<Typography variant="h5"  sx={{ml: 2, mt: 1}}>
								Last 30 Days Top-Gaining Keywords
							</Typography>

							<CardContent>
								<TopGainingWidgets aggregation={'keywords'} summaryType={'stable_avg_revenue_ratio'} periodId={'30d'}/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<Typography variant="h5" sx={{ml: 2, mt: 1}}>
								Last 90 Days Top-Gaining Keywords
							</Typography>

							<CardContent>
								<TopGainingWidgets aggregation={'keywords'} summaryType={'stable_avg_revenue_ratio'} periodId={'90d'}/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>


			<Box sx={{ m: 1 }}>
				<Typography variant="h4">Offers</Typography>

				<Grid container spacing={3} sx={{mt: 1}}>
					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<Typography variant="h5" sx={{ml: 2, mt: 1}}>
								Last 7 Days Top-Gaining offers
							</Typography>

							<CardContent>
								<TopGainingWidgets aggregation={'offers'} summaryType={'stable_avg_revenue_ratio'} periodId={'7d'}/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<Typography variant="h5"  sx={{ml: 2, mt: 1}}>
								Last 30 Days Top-Gaining offers
							</Typography>

							<CardContent>
								<TopGainingWidgets aggregation={'offers'} summaryType={'stable_avg_revenue_ratio'} periodId={'30d'}/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<Typography variant="h5" sx={{ml: 2, mt: 1}}>
								Last 90 Days Top-Gaining offers
							</Typography>

							<CardContent>
								<TopGainingWidgets aggregation={'offers'} summaryType={'stable_avg_revenue_ratio'} periodId={'90d'}/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
