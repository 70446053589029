import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CreateButton = styled(Link)`
	display: inline-block;
	padding: 9px 30px;
	background: #265b97;
	border-radius: 5px;
	margin: 28px 0 20px auto;
	transition: all 0.2s ease;
	& > span {
		font-weight: 400;
		font-size: 18px;
		text-align: center;
		color: #ffffff;
	}
	&:hover {
		background: #0a396b;
	}
`;
