import styled from 'styled-components';

export const Input = styled.input`
	background: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	font-weight: 400;
	font-size: 14px;
	padding: 10px;
	color: #000;
	max-width: 210px;
	width: 100%;
	&::placeholder {
		color: #7d7d7d;
	}
	&:hover,
	&:focus {
		border: 1px solid #265b97;
	}
`;

export const InputLanguage = styled.input`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 6px;
	width: 50px;
	height: 50px;
	border: 1px solid #e4e4ef;
	border-radius: 5px;
	color: #303030;
	font-size: 14px;
	text-align: center;
	font-weight: 400;
	text-transform: uppercase;
	background: ${props =>
		props.activeLanguage ? 'rgb(228, 228, 239)' : 'transparent'};
	position: relative;
	transition: all 0.2s ease;
	&:hover,
	&:focus {
		background: rgb(228, 228, 239);
	}
`;
