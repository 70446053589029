const COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES = { code: 'all', name: 'All' };
const ACCOUNT_ID_TO_SELECT_ALL_ACCOUNTS = { id: 'all', name: 'All' };
const MAX_TABLE_RECORDS_TO_RENDER = 200;
const DEFAULT_MIN_NUMBER_OF_CLICKS = 20;
const DEFAULT_MIN_AVG_REVENUE = 0;

const TRAFFIC_SOURCES = [
	{id: 'all', name: 'All'}, 
	{id: 'tiktok', name: 'TikTok'}, 
	{id: 'facebook', name: 'Facebook'}
];

const AFFILIATES = [
	{id: 'tonic', name: 'Tonic'}, 
	{id: 'crossroads', name: 'Crossroads'}
];

export const analysisConstants = Object.freeze({
	COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES,
	ACCOUNT_ID_TO_SELECT_ALL_ACCOUNTS,
	MAX_TABLE_RECORDS_TO_RENDER,
	TRAFFIC_SOURCES,
	AFFILIATES,
	DEFAULT_MIN_NUMBER_OF_CLICKS,
	DEFAULT_MIN_AVG_REVENUE
});
