import styled from 'styled-components';

export const Button = styled.button`
	display: block;
	max-width: 112px;
	padding: 9px 30px;
	background: #265b97;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.2s ease;
	> span {
		font-weight: 400;
		font-size: 18px;
		text-align: center;
		color: #ffffff;
	}
	&:hover {
		background: #0a396b;
	}
	&:disabled {
		background-color: grey;
		cursor: not-allowed;
	}
`;
