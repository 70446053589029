import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import apiCallsService from '../services/apiCalls.service';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController
);


const chartTypes = {
	"day-of-week-seasonality": {
		labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
		periodKey: 'day_of_week',
		appearance: {
			series: {
				average: {
					type: 'bar',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				},
				median: {
					type: 'bar',
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
				}
			}
		}
	},
	"month-seasonality": {
		labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		periodKey: 'month',
		appearance: {
			series: {
				average: {
					type: 'bar',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				},
				median: {
					type: 'bar',
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
				}
			}
		}
	},
	"history-by-day": {
		periodKey: 'day',
		appearance: {
			series: {
				average: {
					type: 'line',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
					borderColor: 'rgba(255, 99, 132, 0.5)',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 10
				},
				median: {
					type: 'line',
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
					borderColor: 'rgba(53, 162, 235, 0.5)',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 10
				},
				vwap: {
					type: 'line',
					backgroundColor: '#D400FF',
					borderColor: '#D400FF',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 10
				}
			}
		}
	},
	"country": {
		periodKey: 'country',
		appearance: {
			series: {
				average: {
					type: 'bar',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				},
				median: {
					type: 'bar',
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
				}
			}
		}
	},
	"hours-seasonality": {
		labels: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
		periodKey: 'hour',
		appearance: {
			series: {
				average: {
					type: 'bar',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				},
				median: {
					type: 'bar',
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
				}
			}
		}
	},
	"history-by-month": {
		periodKey: 'month',
		appearance: {
			series: {
				average: {
					type: 'line',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
					borderColor: 'rgba(255, 99, 132, 0.5)',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 10
				},
				median: {
					type: 'line',
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
					borderColor: 'rgba(53, 162, 235, 0.5)',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 10
				},
				vwap: {
					type: 'line',
					backgroundColor: '#D400FF',
					borderColor: '#D400FF',
					borderWidth: 2,
					pointRadius: 1,
					pointHoverRadius: 10
				}
			}
		}
	}
};


const defaultChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: true
		},
	},
	scales: {
		clickAxis: {
			type: 'linear',
			position: 'right',
			grid: {
				display: false,
				drawOnChartArea: false
			},
			title: {
				display: true,
				text: 'Clicks'
			}
		},
		revenueAxis: {
			type: 'linear',
			position: 'left',
			title: {
				display: true,
				text: 'Revenue'
			}
		}
	}
};

export const SeasonalityChart = ({affilateId, keyword, periodType, offerId, country, fromDate, toDate, accountId, trafficSource}) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [dataChart, setDataChart] = useState([]);

	const fetchDayOfWeekSeasonalityData = async () =>{
		setLoading(true);
		setError('');

		const response = await apiCallsService.getSeasonalityByPeriodData(offerId, periodType, { affilateId, keyword, country, fromDate, toDate, accountId, trafficSource });

		if (response.error) {
			setError(response.error);
			setLoading(false);

			return;
		}

		setDataChart(response);
		setLoading(false);
	};

	useEffect(() => {
		fetchDayOfWeekSeasonalityData();
	}, [keyword, periodType, offerId, country, fromDate, toDate, accountId, trafficSource]);


	const chartTypeOptions = chartTypes[periodType];

	const chartLabels = (() => {
		if (chartTypeOptions.labels) {
			return chartTypeOptions.labels;
		}

		return dataChart.map(point => point[chartTypeOptions.periodKey]);
	})();

	const chartPoints = (() => {
		if (!chartTypeOptions.labels) {
			return dataChart;
		}

		return chartTypeOptions.labels.map(label => {
			return dataChart.find(point => point[chartTypeOptions.periodKey] === label) || {totalClicks: 0, averageRevenue: 0, median: 0};
		})
	})();


	const createVWAPSeries = (series, length = 14) => {
		const calculateVWAP = data => {
			let totalVolume = 0;
			let totalPV = 0;

			data.forEach(item => {
				const volume = item.averageRevenue * item.totalClicks;
				totalPV += item.averageRevenue * volume;
				totalVolume += volume;
			});

			return totalVolume > 0 ? totalPV / totalVolume : null;
		};

		return chartPoints.map((point, index) => {
			const points = chartPoints.slice(0, index).slice(-length);

			if (point.length < length) {
				return null;
			}

			return calculateVWAP(points);
		});
	};


	const data = {
		labels: chartLabels,
		datasets: [{
			label: 'clicks',
			data: chartPoints.map(point => point.totalClicks),
			backgroundColor: 'rgba(255,224,99,0.5)',
			yAxisID: 'clickAxis',
			type: 'bar',
			...chartTypeOptions.appearance?.series?.clicks
		}, {
			label: 'average, $',
			data: chartPoints.map(point => point.averageRevenue),
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
			yAxisID: 'revenueAxis',
			type: 'bar',
			...chartTypeOptions.appearance?.series?.average
		}, {
			label: 'median, $',
			data: chartPoints.map(point => point.median),
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
			yAxisID: 'revenueAxis',
			...chartTypeOptions.appearance?.series?.median
		}, chartTypeOptions.appearance?.series?.vwap ? {
			label: 'Volume Weighted Average Price, $',
			data: createVWAPSeries(chartPoints, 14),
			yAxisID: 'revenueAxis',
			...chartTypeOptions.appearance?.series?.vwap
		} : null].filter(Boolean)
	};

	const renderChart = loading
		? <CircularProgress size="4rem" sx={{ mr: '50%', mt: '8%' }}/>
		: error
			? <Alert severity="error">Error: {error}</Alert>
			: <Chart type='bar' options={defaultChartOptions} data={data} />

	return renderChart;
};
