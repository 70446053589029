import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Title } from '../components/Title';
import apiCallsService from '../services/apiCalls.service';
import { Error, ErrorWrapper } from '../styledComponents/Error';
import { SubmitButton } from '../styledComponents/buttons/SubmitButton';
import { Wrapper } from '../styledComponents/Wrapper';
import { Input } from '../styledComponents/form/Input';
import Form from '../styledComponents/form/Form';
import CircularProgress from '@mui/material/CircularProgress';

const ItemTitle = styled.h3`
	font-weight: 400;
	font-size: 18px;
	text-align: right;
	color: #555555;
	width: 123px;
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 31px;
	margin-bottom: 16px;
	&:nth-child(2) {
		${Input} {
			background: #fafafb;
			pointer-events: none;
			&:hover,
			&:focus {
				border: 1px solid #d9d9d9;
			}
		}
	}
	&:nth-child(4) {
		margin-bottom: 30px;
	}
`;

const Group = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	& > span {
		margin-right: 30px;
	}
`;

export const AddPixel = () => {
	const [name, setName] = useState('');
	const [type, setType] = useState('Tiktok');
	const [pixelId, setPixelId] = useState('');
	const [accessToken, setAccessToken] = useState('');
	const [errorBlock, setErrorBlock] = useState(false);
	const [formIsLoading, setFormIsLoading] = useState(false);

	const navigate = useNavigate();

	const handleClick = async event => {
		event.preventDefault();
		setFormIsLoading(true);
		setErrorBlock(false);

		if (!name.length || !pixelId.length || !accessToken.length) {
			return;
		}

		const response = await apiCallsService.createPixelRequest({
			name,
			type,
			pixelId,
			accessToken,
		});

		if (response.error) {
			setErrorBlock(true);
			setFormIsLoading(false);

			return;
		}
		setFormIsLoading(false);
		navigate('/pixels-overview');
	};

	return (
		<>
			<Title title='Add pixel' />
			<Wrapper>
				<ErrorWrapper>
					<Error errorBlock={errorBlock}>
						<span>Failed to add Pixel</span>
					</Error>
				</ErrorWrapper>
				<Form>
					<Item>
						<ItemTitle>Pixel Name</ItemTitle>
						<Input type='text' onChange={e => setName(e.target.value)} />
					</Item>
					<Item>
						<ItemTitle>Pixel type</ItemTitle>
						<Input
							placeholder='Tiktok'
							type='text'
							onChange={e => setType(e.target.value)}
						/>
					</Item>
					<Item>
						<ItemTitle>Pixel ID</ItemTitle>
						<Input type='text' onChange={e => setPixelId(e.target.value)} />
					</Item>
					<Item>
						<ItemTitle>Access token</ItemTitle>
						<Input type='text' onChange={e => setAccessToken(e.target.value)} />
					</Item>
					<Group>
						{formIsLoading && <CircularProgress />}
						<SubmitButton
							onClick={handleClick}
							disabled={
								!name.length ||
								!pixelId.length ||
								!accessToken.length ||
								formIsLoading
							}
						>
							<span>Create</span>
						</SubmitButton>
					</Group>
				</Form>
			</Wrapper>
		</>
	);
};
