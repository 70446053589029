import styled from 'styled-components';

export const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	min-width: 125px;
	padding: 9px 30px;
	transition: background-color 0.2s ease;
	font-size: 18px;
	color: #ffffff;
	background-color: #265b97;
	float: right;

	&:hover {
		background-color: #0a396b;
		cursor: pointer;
	}

	&:disabled {
		background-color: grey;
		cursor: not-allowed;
	}
`;
