import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import apiCallsService from "../../services/apiCalls.service";
import {observer} from "mobx-react-lite";

import { analysisConstants } from '../../constants/analysis';

const AFFILATE_ID = 'crossroads';


export const FormCrossroads = observer(({store}) => {
	const [offersMetadata, setOffersMetadata] = useState(null);
	const [countriesMetadata, setCountriesMetadata] = useState(null);
	const [accountsMetadata, setAccountsMetadata] = useState(null)

	const [selectedOffer, setSelectedOffer] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES);

	const [defaultFromDate, setDefaultFromDate] = useState(null);
	const [defaultToDate, setDefaultToDate] = useState(null);
	const [error, setError] = useState('');

	const setLoading = value => {
		store.loading = value;
	};

	const [selectedCrossroadsAccount, setSelectedCrossroadsAccount] = useState(analysisConstants.ACCOUNT_ID_TO_SELECT_ALL_ACCOUNTS);
	const [selectedTrafficSource, setSelectedTrafficSource] = useState(analysisConstants.TRAFFIC_SOURCES[0]);



	const setTableData = data => store.setData(data);

	const fetchMetadata = async() => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getKeywordsPageMetadata(AFFILATE_ID);

			if (!response.countries || !response.offers) {
				return setError('Failed to fetch metadata');
			}

			setOffersMetadata(response.offers);
			setCountriesMetadata([analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES, ...response.countries]);
			setDefaultFromDate('2023-11-06');
			setDefaultToDate(dayjs().subtract(1, 'days').format('YYYY-MM-DD'));
			setAccountsMetadata([analysisConstants.ACCOUNT_ID_TO_SELECT_ALL_ACCOUNTS, ...response.accounts]);

			const defaultOffer = response.offers.find(offer => offer.id === 'dental_implants') || response.offers[0];

			setSelectedOffer(defaultOffer);

			const defaultCountry = response.countries.find(offer => offer.code === 'US') || analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES;
			setSelectedCountry(defaultCountry);
		} catch (err) {
			setError('Failed to fetch metadata');
		} finally {
			setLoading(false);
		}
	};


	const fetchKeywordsData = async () => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getKeywordsDataForOffer({
				affilateId: AFFILATE_ID,
				offer: selectedOffer.id,
				country: selectedCountry.code,
				fromDate: store.pageModel.fromDate,
				toDate: store.pageModel.toDate,
				accountId: selectedCrossroadsAccount.id,
				trafficSource: selectedTrafficSource.id
			});

			if (response.error) {
				setError(response.error);
				setTableData([]);
			}
			else {
				response.table.forEach(record => {
					record.volume = record.avg * record.clicks;
				});

				setTableData(response.table || []);
			}
		} catch (err) {
			setError('Failed to fetch data');
			setTableData([]);
		} finally {
			setLoading(false);
		}
	};


	useEffect(() => {
		if (!offersMetadata) {
			return;
		}

		fetchKeywordsData();
	}, [selectedOffer, selectedCountry, offersMetadata, store.pageModel.fromDate, store.pageModel.toDate, selectedCrossroadsAccount, selectedTrafficSource]);


	useEffect(() => {
		fetchMetadata();
	}, []);

	return (<>
		{!offersMetadata && <Box display="flex" justifyContent="center" alignItems="center">
			<CircularProgress size="5rem" style={{ marginTop: '20%' }}/>
		</Box>}

		{offersMetadata && <>
			<FormControl fullWidth sx={{ mb: '20px' }}>
				<Autocomplete
					options={countriesMetadata}
					getOptionLabel={(option) => option.name}
					value={selectedCountry}
					onChange={(event, newValue) => setSelectedCountry(newValue)}
					renderInput={(params) => <TextField {...params} label="Country" />}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: '20px' }}>
				<Autocomplete
					options={offersMetadata}
					getOptionLabel={(option) => option.name}
					value={selectedOffer}
					onChange={(event, newValue) => setSelectedOffer(newValue)}
					renderInput={(params) => <TextField {...params} label="Offer" />}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: '20px' }}>
				<Autocomplete
					options={accountsMetadata}
					getOptionLabel={(option) => option.name}
					value={selectedCrossroadsAccount}
					onChange={(event, newValue) => setSelectedCrossroadsAccount(newValue)}
					renderInput={(params) => <TextField {...params} label="Account" />}
				/>
			</FormControl>

			<FormControl fullWidth sx={{ mb: '20px' }}>
				<Autocomplete
					options={analysisConstants.TRAFFIC_SOURCES}
					getOptionLabel={(option) => option.name}
					value={selectedTrafficSource}
					onChange={(event, newValue) => setSelectedTrafficSource(newValue)}
					renderInput={(params) => <TextField {...params} label="Traffic source" />}
				/>
			</FormControl>
		</>}
	</>);
})