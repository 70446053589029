import { makeAutoObservable } from 'mobx';

import apiCallsService from '../../services/apiCalls.service';

class ImportProcessObserverStore {
	id;
	name;
	status = 'loading';
	_interval = null;

	constructor(id, name) {
		makeAutoObservable(this);

		this.id = id;
		this.name = name;
		this._interval = setInterval(() => this.fetchStatus(), 2000);
	}

	async fetchStatus() {
		const response = await apiCallsService.getImportAttachmentStatus(this.id);
		
		this.status = (() => {
			if (response.error || !response.status) {
				return 'failed';
			}
		
			return response.status;
		})();
		
		if (this.status === 'success' || this.status === 'failed') {
			clearInterval(this._interval);
		
			this._interval = null;
		}
	}

	release() {
		this._interval && clearInterval(this._interval);

		this._interval = null;
	}
}

class DownloadAttachmentsStore {
	importProcesses = [];

	constructor() {
		makeAutoObservable(this);
	}

	setImportProcesses(imports) {
		this.releaseAllImportProcesses();

		this.importProcesses = imports.map(importProcess => new ImportProcessObserverStore(importProcess.importModelId, importProcess.name));
	}

	releaseAllImportProcesses() {
		this.importProcesses.forEach(importProcess => importProcess.release());
	}

	get progress() {
		const total = this.importProcesses.length;
		const completed = this.importProcesses.filter(p => p.status === "success").length;

		return total ? (completed / total) * 100 : 0;
	}
}

export default DownloadAttachmentsStore;
