import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
	width: calc(100% - 203px);
	.select-campaign__control {
		cursor: text;
	}
	& > div > div > div {
		font-weight: 400;
		font-size: 14px;
	}
`;

export const StyledSelectCampaignOverview = styled(Select)`
	width: 70%;
	height: 25px;

	.select__dropdown-indicator {
		position: absolute;
		right: -30px;
		top: -6px;
	}
	.select__input-container {
		padding: 0;
	}
	.select__control {
		min-height: auto;
		height: 100%;
	}
	.select__indicator-separator {
		display: none;
	}
	.select__value-container {
		padding: 0;
	}
	.select__single-value {
		font-size: 8px;
		text-align: center;
		text-overflow: unset;
	}

	.select__option {
		font-size: 8px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 26px;
	}
`;
