import React, { useEffect, useState } from 'react';
import { Title } from '../components/Title';
import apiCallsService from '../services/apiCalls.service';
import TablePage from '../styledComponents/pages/TablePage';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import trash from '../assets/trash.svg';
import { CreateButton } from '../styledComponents/buttons/CreateButton';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

export const PixelsOverview = () => {
	const [pixelInfo, setPixelInfo] = useState([]);
	const [pixelId, setPixelId] = useState();
	const [errorModalText, setErrorModalText] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};


	const handleDelete = id => {
		setPixelId(id);
		setOpen(true);
	};


	const createPixelsOverviewResponse = async () => {
		const response = await apiCallsService.createPixelOverviewRequest();
		setIsLoading(false);
		if (response.error) {
			setErrorModalText('Failed to display pixels overview');
			return;
		}

		setPixelInfo(response);
	};


	useEffect(() => {
		createPixelsOverviewResponse();
	}, []);


	const handleSubmitDelete = async() => {
		const response = await apiCallsService.deletePixelRequest(pixelId);

		if (response.error) {
			setErrorModalText('Failed to delete pixel');
			setOpen(false);
			return;
		}

		setErrorModalText('');
		setOpen(false);
		await createPixelsOverviewResponse();
	};


	return (
		<>
			<Title title='Pixels overview' />

			<TablePage>
				{errorModalText && <Alert severity="error" style={{marginBottom: '10px'}}>{errorModalText}</Alert>}

				<CreateButton to='/add-pixel'>
					<span>Add pixel</span>
				</CreateButton>
				{isLoading ? (
					<div className='loading-box'>
						<CircularProgress />
					</div>
				) : (
					<>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Pixel Name</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Pixel Id</TableCell>
									<TableCell>Access token</TableCell>
									<TableCell className='action-buttons'></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{pixelInfo?.map(item => (
									<TableRow key={item.id}>
										<TableCell>{item.name}</TableCell>
										<TableCell>{item.type}</TableCell>
										<TableCell>{item.pixelId}</TableCell>
										<TableCell>{item.accessToken}</TableCell>
										<TableCell align='right' className='action-buttons'>
											<IconButton onClick={() => handleDelete(item.id)}>
												<img src={trash} alt='delete' />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</>
				)}
			</TablePage>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Delete Pixel Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this pixel?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button onClick={handleSubmitDelete} autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
