import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { SeasonalityChart } from '../components/SeasonalityChart';

const AVAILABLE_ANALYSIS = [
	{ id: 'history-by-day', title: 'History by day' },
	{ id: "history-by-month", title: 'History by month' },
	{ id: "hours-seasonality", title: 'Hours seasonality [PST -8]' },
	{ id: 'day-of-week-seasonality', title: 'Day of week seasonality' },
	{ id: 'month-seasonality', title: 'Monthly seasonality' }, 
	{ id: 'country', title: 'By countries' }
];

export default function ModalSeasonalityChartByKeyboard({affilateId, open, setOpen, keyword, offerId, country, fromDate, toDate, accountId, trafficSource}) {
	const handleClose = () => {
		setOpen(false);
	};

  return (
	<>
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth={true}
			maxWidth={'xl'}
		>
			<DialogTitle id="alert-dialog-title">
				Seasonality by keyword: <b>{keyword}</b>
			</DialogTitle>
			<DialogContent>
				{AVAILABLE_ANALYSIS.map((analysis) => (
					<Box style={{height: "400px"}} sx={{ mb: '40px' }} key={analysis.id}>
						<SeasonalityChart
							affilateId={affilateId}
							periodType={analysis.id}
							keyword={keyword}
							offerId={offerId}
							country={country}
							fromDate={fromDate}
							toDate={toDate}
							accountId={accountId}
						/>
						<h5 style={{textAlign: 'center'}}>{analysis.title}</h5>
					</Box>
				))}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Ok</Button>
			</DialogActions>
		</Dialog>
	</>
  );
};
