export const options = [
	{ value: 'Ukraine', label: 'Ukraine' },
	{ value: 'Canada', label: 'Canada' },
	{ value: 'USA', label: 'USA' },
	{ value: 'Brazil', label: 'Brazil' },
];

export const statusOptions = [
	{ value: '', label: '' },
	{ value: 'Active', label: 'Active' },
	{ value: 'Pending', label: 'Pending' },
];
