import React, {useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {TableWidget} from "./TableWidget";

export const TopGainingWidgets = ({aggregation, summaryType, periodId, defaultMinNumberOfClicks = 50, defaultMinAVGRevenue = 0}) => {
	const [minNumberOfClicks, setMinNumberOfClicks] = useState(defaultMinNumberOfClicks);
	const [minAVGRevenue, setMinAVGRevenue] = useState(defaultMinAVGRevenue);

	return (
		<>
			<Box display={'flex'}>
				<TextField
					onChange={event => setMinNumberOfClicks(event.target.value)}
					type="number"
					defaultValue={minNumberOfClicks}
					variant="outlined"
					label="Conversions > x"
					size="small"
					sx={{mb: 1, mr: 1}}>
				</TextField>

				<TextField
					onChange={event => setMinAVGRevenue(event.target.value * 100)}
					type="number"
					defaultValue={minAVGRevenue || '0.00'}
					variant="outlined"
					label="AVG revenue > x"
					size="small"
					sx={{mb: 1}}>
				</TextField>
			</Box>

			<TableWidget
				aggregation={aggregation}
				summaryType={summaryType}
				periodId={periodId}
				query={{minNumberOfClicks, minAVGRevenue}}
			/>
		</>
	);
}