import dayjs from 'dayjs';

const TIMEFRAME_TO_SELECT_ALL_PERIOD = 'All';
const DEFAULT_FROM_DATE = '2023-01-01';
const DEFAULT_TO_DATE = dayjs().subtract(1, 'days').format('YYYY-MM-DD');

const OFFER_TIMEFRAMES_MAP = {
	'All': {
		title: 'All',
		fromDate: DEFAULT_FROM_DATE,
		toDate: DEFAULT_TO_DATE
	},
	'7d': {
		title: 'Last 7 days',
		fromDate: dayjs(DEFAULT_TO_DATE).subtract(7, 'days').format('YYYY-MM-DD'),
		toDate: DEFAULT_TO_DATE
	},
	'30d': {
		title: 'Last 30 days',
		fromDate: dayjs(DEFAULT_TO_DATE).subtract(30, 'days').format('YYYY-MM-DD'),
		toDate: DEFAULT_TO_DATE
	},
	'90d': {
		title: 'Last 90 days',
		fromDate: dayjs(DEFAULT_TO_DATE).subtract(90, 'days').format('YYYY-MM-DD'),
		toDate: DEFAULT_TO_DATE
	}
};

export const timeframeConstants = Object.freeze({
	DEFAULT_FROM_DATE,
	DEFAULT_TO_DATE,
	TIMEFRAME_TO_SELECT_ALL_PERIOD,
	OFFER_TIMEFRAMES_MAP
});
