import React, { useEffect, useState } from 'react';

import { Title } from '../components/Title';
import ExpandMoreLessButton from '../components/ExpandMoreLessButton';
import apiCallsService from '../services/apiCalls.service';
import TablePage from '../styledComponents/pages/TablePage';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

import { analysisConstants } from '../constants/analysis';
import { timeframeConstants } from '../constants/timeframes';

export const AnalyseOffersList = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [affiliate, setAffiliate] = useState(analysisConstants.AFFILIATES[0])

	const [minNumberOfClicks, setMinNumberOfClicks] = useState(analysisConstants.DEFAULT_MIN_NUMBER_OF_CLICKS);
	const [minAVGRevenue, setMinAVGRevenue] = useState(analysisConstants.DEFAULT_MIN_AVG_REVENUE);

	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);

	const [country, setCountry] = useState(analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES.code)

	const [sortingBy, setSortingBy] = useState('volume');
	const [sortingDirection, setSortingDirection] = useState(false);

	const [offerTimeframe, setOfferTimeframe] = useState('7d');

	const fetchOffersListData = async () => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getAnalysedOffersList({
				affiliateId: affiliate.id,
				period: offerTimeframe,
				country: country,
				minNumberOfClicks: minNumberOfClicks,
				minAVGRevenue: minAVGRevenue
			});

			if (response.error) {
				setError(response.error);
				setTableData([]);
				setFilteredTableData([]);
			}
			else {
				response.forEach(record => {
					record.volume = record.averageRevenue * record.totalClicks;
				});

				setTableData(response || []);
			}
		} catch (err) {
			setError('Failed to fetch data');
			setTableData([]);
			setFilteredTableData([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let result = [...tableData];

		if (sortingBy) {
			if (sortingDirection) {
				result = result.sort((a, b) => a[sortingBy] - b[sortingBy])
			}
			else {
				result = result.sort((a, b) => b[sortingBy] - a[sortingBy])
			}
		}

		setFilteredTableData(result);

	}, [tableData, sortingDirection, sortingBy]);

	useEffect(() => {
		fetchOffersListData();
	}, [affiliate, country, offerTimeframe, minNumberOfClicks, minAVGRevenue]);

	const handleSortingBy = attribute => {
		if (attribute === sortingBy) {
			return setSortingDirection(!sortingDirection);
		}

		setSortingBy(attribute);
		setSortingDirection(false);
	};

	const handleCountryChange = event => {
		const value = (event.target.value).toUpperCase();

		if (value === 'ALL' || value === '') {
			return setCountry(analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES.code);
		}

		if (value.length !== 2) {
			return;
		}

		setCountry(value);
	}

	const getLinkToAnalyseOffer = (affilateId, offerId, country, periodId) => {
		const linkMap = {
			tonic: `/analyse-tonic-offers?`,
			crossroads: `/analyse-crossroads-offers?`
		}

		const queryParam = `country=${country}&offer=${offerId}&periodId=${periodId || 'All'}`;

		return linkMap[affilateId] + queryParam;
	}

	const stylesTableHead = attribute => ({
		color: sortingBy === attribute ? 'rgba(0, 0, 0, 1)': 'rgba(0, 0, 0, 0.6)',
		cursor: 'pointer'
	});

	return (
		<>
			<Title title="Offers list" />
			<TablePage>
				<FormControl sx={{ mb: '20px' }}>
					<ButtonGroup variant="outlined" sx={{justifyContent: 'end'}}>
						{Object.entries(timeframeConstants.OFFER_TIMEFRAMES_MAP).map(([timeframe, data], index) => {
							if (timeframe === 'All') {
								return <></>;
							}

							return <Button onClick={() => setOfferTimeframe(timeframe)} variant={offerTimeframe === timeframe ? 'contained' : 'outlined'} key={index}>{data.title}</Button>
						})}
					</ButtonGroup>
				</FormControl>

				<FormControl fullWidth sx={{ mb: '20px' }}>
					<Autocomplete
						options={analysisConstants.AFFILIATES}
						getOptionLabel={(option) => option.name}
						value={affiliate}
						renderInput={(params) => <TextField {...params} label="Affiliate" />}
						onChange={(event, newValue) => setAffiliate(newValue)}
					/>
				</FormControl>

				<Box sx={{ mb: '10px' }}>
					<h4>Filters</h4>
				</Box>

				<Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mb: '20px' }}>
					<TextField
						onChange={handleCountryChange}
						defaultValue={country.toUpperCase()}
						variant="outlined"
						label="Country"
						size="small">
					</TextField>

					<TextField
						onChange={event => setMinAVGRevenue(event.target.value * 100)}
						type="number"
						defaultValue={minAVGRevenue || '0.00'}
						variant="outlined"
						label="AVG revenue > x"
						size="small">
					</TextField>

					<TextField
						onChange={event => setMinNumberOfClicks(event.target.value)}
						type="number"
						defaultValue={minNumberOfClicks}
						variant="outlined"
						label="Clicks > x"
						size="small"
						sx={{mb: 1, mr: 1}}>
					</TextField>
				</Stack>

				{loading ? (
						<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }}/>
				) : error ? (
						<Alert severity="error">Error: {error}</Alert>
				) : ( <TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Offer</TableCell>
								<TableCell>Country</TableCell>
								<TableCell style={stylesTableHead('avg')} onClick={() => handleSortingBy('averageRevenue')}>AVG <ExpandMoreLessButton enabled={sortingBy === 'averageRevenue'} sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('clicks')} onClick={() => handleSortingBy('totalClicks')}>Clicks <ExpandMoreLessButton enabled={sortingBy === 'totalClicks'} sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('volume')} onClick={() => handleSortingBy('volume')}>Volume <ExpandMoreLessButton enabled={sortingBy === 'volume'} sortingDirection={sortingDirection}/></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredTableData.slice(0, analysisConstants.MAX_TABLE_RECORDS_TO_RENDER).map((record, index) => (
									<TableRow key={index}>
										<TableCell>
											<Link href={getLinkToAnalyseOffer(affiliate.id, record.offerId, record.country, offerTimeframe)} target="_blank">
												{record.offerName}
											</Link>
										</TableCell>
										<TableCell>{record.country}</TableCell>
										<TableCell>{record.averageRevenue}$</TableCell>
										<TableCell>{record.totalClicks}</TableCell>
										<TableCell>{(record.volume).toFixed(0)}$</TableCell>
									</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>)}
			</TablePage>
		</>
	);
};
