import React from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, roles, root }) => {
	const userAccessLevel = localStorage.getItem('userAccessLevel');

	if (!userAccessLevel) {
		return <Navigate to="/login" />;
	}

	if (userAccessLevel === 'designer') {
		const REACT_APP_DESIGNER_APP_URL = process.env.REACT_APP_DESIGNER_APP_URL.replace('__HOST__', window.location.hostname);
		const fullLink = `${REACT_APP_DESIGNER_APP_URL}?auth=${localStorage.userAuthToken}`;

		if (root) {
			return (<div>You have access only for the following services: <a href={fullLink}>Designer app</a></div>);
		}
		else {
			window.location.href = fullLink;
			return '<div>Redirecting to the Designer app</div>';
		}
	}

	if (roles && !roles.includes(userAccessLevel)) {
		return <Navigate to="/download-attachments" />;
	}

	return <Component />;
};
