import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import authService from '../services/auth.service';
import Alert from '@mui/material/Alert';

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #e9e9e9;
`;

const Title = styled.h2`
	font-weight: 700;
	font-size: 32px;
	text-align: center;
	color: #265b97;
	margin-bottom: 33px;
`;

const Form = styled.form`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 400px;
	width: 100%;
`;

const Input = styled.input`
	width: 100%;
	height: 50px;
	margin-bottom: 20px;
	font-weight: 400;
	font-size: 18px;
	color: #000000;
	padding-left: 20px;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	transition: all 0.2s ease;

	&::placeholder {
		color: #7d7d7d;
	}

	&:nth-child(2) {
		margin-bottom: 30px;
	}

	&:hover,
	&:focus {
		border: 1px solid #265b97;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #265b97;
	border-radius: 5px;
	min-width: 122px;
	padding: 14px 30px;
	cursor: pointer;
	transition: all 0.2s ease;

	& > span {
		font-weight: 400;
		font-size: 18px;
		color: #ffffff;
	}
	&:hover {
		background: #0a396b;
	}
`;



export const Login = ({ stateless, redirect }) => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);
	const navigate = useNavigate();

	const handleLogin = async e => {
		e.preventDefault();

		const response = await authService.login(username, password, stateless);

		if (!response || response.error) {
			setError(true);
			return;
		}

		if (redirect) {
			window.location.href = `${redirect}?auth=${response}`;
			return;
		}

		navigate('/');
		window.location.reload();
	};


	useEffect(() => {
		const style = document.createElement('style');

		style.innerHTML = `
			html {
				min-width: auto !important;
			}
		`;
		document.head.appendChild(style);

		return () => {
			document.head.removeChild(style);
		};
	}, []);

	return (
		<Wrapper>
			<Title>dgtlunion</Title>
			{error && <Alert severity="error" style={{marginBottom: '10px'}}>Incorrect username or password</Alert>}
			<Form onSubmit={handleLogin}>
				<Input
					type='text'
					placeholder='Username'
					onChange={e => setUsername(e.target.value)}
				/>
				<Input
					type='password'
					placeholder='Password'
					onChange={e => setPassword(e.target.value)}
				/>
				<Button type='submit'>
					<span>Log in</span>
				</Button>
			</Form>
		</Wrapper>
	);
};
