import React from 'react';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';


class FormStore {
	data = null
	loading = false

	constructor(pageModel) {
		makeAutoObservable(this);

		this.pageModel = pageModel;
	}

	setData(data) {
		this.data = data;
	}
}

class CompareOffersStore {
	fromDate = '2023-11-06'
	toDate = '2023-11-06'

	constructor() {
		makeAutoObservable(this);

		this.storeColumnA = new FormStore(this);
		this.storeColumnB = new FormStore(this);
	}


	initialize() {
		// reset store
	}


	setFromDate(date) {
		this.fromDate = date;
	}

	setToDate(date) {
		this.toDate = date;
	}
}


export default CompareOffersStore;