import axios from 'axios';

import { BASE_URL } from './config.service';

const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'userAuthToken';
const LOCAL_STORAGE_USER_LEVEL = 'userAccessLevel';


const login = async (username, password, stateless = false) => {
	try {
		const response = await axios.post(BASE_URL + '/api/login',
		{
			username,
			password,
		},
		{ withCredentials: true });

		if (stateless) {
			return response.data.token;
		}

		if (!response.data.accessLevel) {
			localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
			localStorage.removeItem(LOCAL_STORAGE_USER_LEVEL);
			window.location.reload();
			return;
		}

		response.data.token && localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, response.data.token);
		localStorage.setItem(LOCAL_STORAGE_USER_LEVEL, response.data.accessLevel);

		return response.data.token;
	} catch (error) {
		return { error: error.message };
	}
};


const logOut = () => {
	localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
	localStorage.removeItem(LOCAL_STORAGE_USER_LEVEL);

	window.location.reload();
};


const getAuthToken = () => {
	return localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
};


const getAccessLevel = () => {
	return localStorage.getItem(LOCAL_STORAGE_USER_LEVEL);
};

const authService = {
	login,
	logOut,
	getAuthToken,
	getAccessLevel
};


export default authService;
