import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Title } from '../components/Title';
import trash from '../assets/trash.svg';
import { Input } from '../styledComponents/form/Input';
import apiCallsService from '../services/apiCalls.service';
import Form from '../styledComponents/form/Form';
import { IconButton } from '@mui/material';
import { ButtonAddAccount } from '../styledComponents/buttons/AddAccountButton';
import { Button } from '../styledComponents/buttons/SaveButton';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from "@mui/material/Alert";

const Wrapper = styled.div`
	padding: 30px 90px;
`;

const FormProfile = styled(Form)`
	max-width: 200px;
	margin-top: 21px;
`;

const FormTitle = styled.h2`
	font-weight: 400;
	font-size: 32px;
	color: #555555;
	margin-top: 16px;
`;

const Item = styled.div`
	position: relative;
	margin-bottom: 20px;
`;

const IconButtonDelete = styled(IconButton)`
	position: absolute !important;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	z-index: 3;
`;

const Group = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	& > span {
		margin-left: 30px;
	}
`;

export const MyProfile = () => {
	const [errorModalText, setErrorModalText] = useState('');
	const [errorBlock, setErrorBlock] = useState(false);
	const [inputValues, setInputValues] = useState([]);
	const [oldInputValues, setOldInputValues] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);



	const handleDeleteInputOld = value => {
		const filterInputs = accounts.filter(input => input !== value);
		filterInputs && setAccounts(filterInputs);
	};

	const handleDeleteInputNew = (key, e) => {
		e.preventDefault();
		const filterNewInputs = inputValues.filter(input => input.key !== key);
		filterNewInputs && setInputValues(filterNewInputs);
	};

	const createAccountsOverviewResponse = async () => {
		setIsLoading(true);
		setInputValues([]);

		const response = await apiCallsService.createAccountsOverviewRequest();
		setIsLoading(false);

		if (response.error) {
			setErrorModalText('Failed to display accounts');
			setErrorBlock(true);
			return;
		}

		setAccounts(response);
	};


	useEffect(() => {
		createAccountsOverviewResponse();
	}, []);

	const handleInputChange = (key, e) => {
		const { value } = e.target;
		setInputValues(prevValues => {
			const updatedValues = [...prevValues];
			const inputIndex = updatedValues.findIndex(input => input.key === key);
			updatedValues[inputIndex].value = value;
			return updatedValues;
		});
	};

	const handleOldInputChange = (value, index) => {
		setOldInputValues(prevValues => {
			return { ...prevValues, [index]: value };
		});
	};

	const handleAddInput = e => {
		e.preventDefault();

		setInputValues(prevValues => [
			...prevValues,
			{ key: Date.now(), value: '' },
		]);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setErrorBlock(false);
		setFormIsLoading(true);
		let updatedArray;

		const accountsArrayValues = inputValues.map(item => item.value);

		if (oldInputValues) {
			for (const [key, value] of Object.entries(oldInputValues)) {
				accounts[key] = value;
			}
		}

		if (accountsArrayValues) {
			if (accounts.length > 0) {
				updatedArray = accounts.concat(accountsArrayValues);
			} else {
				updatedArray = accountsArrayValues;
			}
		}

		const response = await apiCallsService.createAddAccountsRequest(
			updatedArray
		);

		if (response.error) {
			setErrorModalText('Failed to add accounts');
			setErrorBlock(true);
			setFormIsLoading(false);
			return;
		}

		setFormIsLoading(false);
		createAccountsOverviewResponse();
	};

	return (
		<>
			<Title title='My profile' />
			<Wrapper>
				{errorBlock && <Alert severity="error" style={{marginBottom: '10px'}}>{errorModalText}</Alert>}

				<FormTitle>Add TikTok ad accounts</FormTitle>
				<FormProfile onSubmit={handleSubmit}>
					{isLoading && (
						<div style={{ marginBottom: '25px' }}>
							<CircularProgress />
						</div>
					)}
					{accounts &&
						accounts.map((item, index) => (
							<Item key={item}>
								<Input
									type='text'
									defaultValue={item}
									onChange={e => handleOldInputChange(e.target.value, index)}
								/>
								<IconButtonDelete onClick={() => handleDeleteInputOld(item)}>
									<img src={trash} alt='delete' />
								</IconButtonDelete>
							</Item>
						))}

					{inputValues.map(input => (
						<Item key={input.key}>
							<Input
								type='text'
								onChange={e => handleInputChange(input.key, e)}
							/>
							<IconButtonDelete
								onClick={e => handleDeleteInputNew(input.key, e)}
							>
								<img src={trash} alt='deleteBtn' />
							</IconButtonDelete>
						</Item>
					))}

					<ButtonAddAccount onClick={handleAddInput}>
						<span>Add new account</span>
					</ButtonAddAccount>
					<Group>
						<Button type='submit' disabled={formIsLoading}>
							<span>Save</span>
						</Button>
						{formIsLoading && <CircularProgress />}
					</Group>
				</FormProfile>
			</Wrapper>
		</>
	);
};
