import React from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import authService from '../services/auth.service';

const Header = styled.div`
	background: #265b97;
	height: 59px;
	padding: 15px 10px 15px 30px;
	& > span {
		font-weight: 400;
		font-size: 24px;
		color: #ffffff;
	}
`;

export const Title = ({ title }) => {
	const handleClick = () => {
		authService.logOut();
	};

	return (
		<Header>
			<span>{title}</span>

			<div className='logout-btn-holder'>
				<Button onClick={handleClick}>Log Out</Button>
			</div>
		</Header>
	);
};
