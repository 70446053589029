import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import {Title} from "../../components/Title";
import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import {FormCrossroads} from './FormCrossroads';
import {FormTonic} from './FormTonic';
import {TableCompare} from './TableCompare';
import TablePage from '../../styledComponents/pages/TablePage';

import CompareOffersStore from './store';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { analysisConstants } from '../../constants/analysis';
import { timeframeConstants } from '../../constants/timeframes';

const store = new CompareOffersStore();


export const CompareOffers = observer(() => {
	const setSelectedFromDate = date => store.setFromDate(date);
	const setSelectedToDate = date => store.setToDate(date);
	const [offerTimeframe, setOfferTimeframe] = useState(null);
	const [affiliateColumnA, setAffiliateColumnA] = useState(analysisConstants.AFFILIATES[0]);
	const [affiliateColumnB, setAffiliateColumnB] = useState(analysisConstants.AFFILIATES[1]);

	const setAffiliate = (affiliate, columnId) => {
		if (columnId === 'a') {
			setAffiliateColumnA(affiliate);
		}
		else {
			setAffiliateColumnB(affiliate);
		}
	}


	const handleOfferDataByTimeframe = (timeframe, data) => {
		setOfferTimeframe(timeframe);
		setSelectedFromDate(data.fromDate);
		setSelectedToDate(data.toDate);
	}


	useEffect(() => {
		store.initialize();

		store.setFromDate(timeframeConstants.DEFAULT_FROM_DATE);
		store.setToDate(timeframeConstants.DEFAULT_TO_DATE);
	}, []);


	return (
		<>
			<Title title="Compare offer keywords" />
			<TablePage>
				<FormControl sx={{ mb: '20px' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Stack direction="row" spacing={2} alignItems="center" mb={'20px'}>
							<DatePicker
								minDate={dayjs(timeframeConstants.DEFAULT_FROM_DATE)}
								maxDate={dayjs(timeframeConstants.DEFAULT_TO_DATE)}
								value={dayjs(timeframeConstants.DEFAULT_FROM_DATE)}
								onChange={(newValue) => setSelectedFromDate(dayjs(newValue).format('YYYY-MM-DD'))}
								format="YYYY-MM-DD"
							/>
							<span>-</span>
							<DatePicker
								minDate={dayjs(timeframeConstants.DEFAULT_FROM_DATE)}
								maxDate={dayjs(timeframeConstants.DEFAULT_TO_DATE)}
								value={dayjs(timeframeConstants.DEFAULT_TO_DATE)}
								onChange={(newValue) => setSelectedToDate(dayjs(newValue).format('YYYY-MM-DD'))}
								format="YYYY-MM-DD"
							/>
						</Stack>
					</LocalizationProvider>

					<ButtonGroup variant="outlined" sx={{justifyContent: 'end'}}>
						{Object.entries(timeframeConstants.OFFER_TIMEFRAMES_MAP).map(([timeframe, data], index) => (
							<Button onClick={() => handleOfferDataByTimeframe(timeframe, data)} variant={offerTimeframe === timeframe ? 'contained' : 'outlined'} key={index}>{data.title}</Button>
						))}
					</ButtonGroup>
				</FormControl>



			<Grid container spacing={3} sx={{mt: 1}}>
				<Grid item xs={12} md={6}>
					<FormControl fullWidth sx={{ mb: '20px' }}>
						<Autocomplete
							options={analysisConstants.AFFILIATES}
							getOptionLabel={(option) => option.name}
							value={affiliateColumnA}
							renderInput={(params) => <TextField {...params} label="Affiliate" />}
							onChange={(event, newValue) => setAffiliate(newValue, 'a')}
						/>
					</FormControl>

					{affiliateColumnA.id === 'crossroads'
						? <FormCrossroads store={store.storeColumnA}/>
						: <FormTonic store={store.storeColumnA} />}
				</Grid>

				<Grid item xs={12} md={6}>
					<FormControl fullWidth sx={{ mb: '20px' }}>
						<Autocomplete
							options={analysisConstants.AFFILIATES}
							getOptionLabel={(option) => option.name}
							value={affiliateColumnB}
							renderInput={(params) => <TextField {...params} label="Affiliate" />}
							onChange={(event, newValue) => setAffiliate(newValue, 'b')}
						/>
					</FormControl>

					{affiliateColumnB.id === 'crossroads'
						? <FormCrossroads store={store.storeColumnB}/>
						: <FormTonic store={store.storeColumnB} />}
				</Grid>
			</Grid>

			<TableCompare mode="inner" keywordsA={store.storeColumnA} keywordsB={store.storeColumnB} />

			</TablePage>
		</>
	);
});