import React from 'react';

import Box from '@mui/material/Box';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function ExpandMoreLessButton({enabled, sortingDirection}) {
	return (
		<>
			{enabled
				?	<>
						{ sortingDirection
						? <ExpandLess sx={{width: '20px', verticalAlign: 'middle'}}/>
						: <ExpandMore sx={{width: '20px', verticalAlign: 'middle'}}/>}
					</>
				: <Box width={'20px'} display={'inline-block'}></Box>}
		</>
	)
};
