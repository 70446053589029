import React, {useState} from "react";
import moment from "moment/moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {TableWidget} from "./TableWidget";

export const SeasonalityOffersWidget = ({title, summaryType = 'offers', defaultLimit = 20, defaultMinNumberOfClicks = 50, defaultMinAVGRevenue = 0}) => {
	const [selectedDay, setSelectedDay] = useState(moment().add(1, 'day').format('dddd').toLowerCase());
	const [compareToOfferLimit, setCompareToOfferLimit] = useState(defaultLimit);
	const [minNumberOfClicks, setMinNumberOfClicks] = useState(defaultMinNumberOfClicks);
	const [minAVGRevenue, setMinAVGRevenue] = useState(defaultMinAVGRevenue);

	const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

	return (
		<Card variant="outlined">
			<CardContent>
				<Typography variant="h5" sx={{ml: 0, mb: 2}}>{title}</Typography>

				<ButtonGroup variant="outlined" sx={{mb: 1, mr: 1}}>
					{days.map(day =>
						<Button onClick={() => setSelectedDay(day)} variant={selectedDay === day ? 'contained' : 'outlined'}>{day}</Button>
					)}
				</ButtonGroup>

				<TextField
					onChange={event => setCompareToOfferLimit(event.target.value)}
					defaultValue={compareToOfferLimit}
					select
					variant="outlined"
					label="Items"
					size="small"
					sx={{mb: 1, mr: 1}}>

					<MenuItem key={10} value={10}>10</MenuItem>
					<MenuItem key={20} value={20}>20</MenuItem>
					<MenuItem key={50} value={50}>50</MenuItem>
					<MenuItem key={100} value={100}>100</MenuItem>
					<MenuItem key={500} value={500}>500</MenuItem>
				</TextField>

				<TextField
					onChange={event => setMinNumberOfClicks(event.target.value)}
					type="number"
					defaultValue={minNumberOfClicks}
					variant="outlined"
					label="Conversions > x"
					size="small"
					sx={{mb: 1, mr: 1}}>
				</TextField>

				<TextField
					onChange={event => setMinAVGRevenue(event.target.value * 100)}
					type="number"
					defaultValue={minAVGRevenue || '0.00'}
					variant="outlined"
					label="AVG revenue > x"
					size="small">
				</TextField>

				<TableWidget
					aggregation={'offers'}
					summaryType={summaryType}
					periodId={'90d'}
					limit={compareToOfferLimit}
					inputProps={{ maxLength: 2 }}
					query={{day: selectedDay, minNumberOfClicks, minAVGRevenue}}
				/>
			</CardContent>
		</Card>
	);
}