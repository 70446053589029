import './App.css';
import { Home } from './pages/Home';
import { BrowserRouter } from 'react-router-dom';
import { Login } from './pages/Login';
import { useEffect, useState } from 'react';
import authService from './services/auth.service';

function App() {
	const [currentUser, setCurrentUser] = useState(undefined);

	useEffect(() => {
		const authToken = authService.getAuthToken();

		if (authToken) {
			const accessLevel = authService.getAccessLevel();

			if (!accessLevel) {
				console.log('New version of auth data needed. Signing out...');
				authService.logOut();
			}
		}

		authToken && setCurrentUser(authToken);
	}, []);
	return <BrowserRouter>{currentUser ? <Home /> : <Login />}</BrowserRouter>;
}

export default App;
