import React, { useState, useEffect } from "react";
import { observer } from 'mobx-react-lite';
import { Title } from "../../components/Title";
import DownloadAttachmentsStore from './store';

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import apiCallsService from "../../services/apiCalls.service";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import Grid from "@mui/material/Grid";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const store = new DownloadAttachmentsStore();


function LinearProgressWithLabel(props) {
	return (
	  <Box sx={{ display: 'flex', alignItems: 'center' }}>
		<Box sx={{ width: '100%', mr: 1 }}>
		  <LinearProgress variant="determinate" {...props} />
		</Box>
		<Box sx={{ minWidth: 35 }}>
		  <Typography variant="body2" color="text.secondary">{`${Math.round(
			props.value,
		  )}%`}</Typography>
		</Box>
	  </Box>
	);
  }

export const DownloadAttachments = observer(() => {
	const [link, setLink] = useState("");
	const [taskId, setTaskId] = useState(null);

	const [linkIsValid, setLinkIsValid] = useState(false);
	const [errorText, setErrorText] = useState("");
	const [metadata, setMetadata] = useState(null);
	const [selectedBusinessCenter, setSelectedBusinessCenter] = useState(null);
	const [selectedAdsManager, setSelectedAdsManager] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const parseTaskIdFromTheAsanaLink = asanaLink => {
		const regexLink = /^https:\/\/app\.asana\.com/;

		if (!asanaLink.match(regexLink)) {
			return { error: "Wrong link to the asana task" };
		}

		const url = new URL(asanaLink);
		const taskId = url.pathname.split("/")[3];

		if (!taskId) {
			return { error: "Wrong link to the asana task" };
		}

		return { taskId };
	};


	function handleInputChange(event) {
		const { taskId, error } = parseTaskIdFromTheAsanaLink(event.target.value);

		if (error) {
			setErrorText(error);
			setLinkIsValid(false);
			setTaskId(null);

			return;
		}

		const linkForDownloadFile = apiCallsService.linkToDownloadCreativesZipFromAsana(taskId);

		setErrorText(false);
		setLinkIsValid(true);
		setLink(linkForDownloadFile);
		setTaskId(taskId);
	}


	const importToFacebook = async() => {
		setIsLoading(true);

		const response = await apiCallsService.importAttachment({
			asanaTaskId: taskId,
			integrationType: 'facebook',
			businessCenterId: selectedBusinessCenter.businessCenter.id,
			adAccountId: selectedAdsManager.id
		});

		localStorage.facebookImporterPreset = JSON.stringify({
			businessCenterId: selectedBusinessCenter.businessCenter.id,
			adAccountId: selectedAdsManager.id
		});

		setIsLoading(false);

		const messageByErrorId = {
			asana_failed: 'Failed to get attachments from the asana ticket.',
			default: 'Failed to import'
		};

		if (response.error) {
			return setErrorText(messageByErrorId[response.error] || messageByErrorId.default);
		}

        store.setImportProcesses(response.imports);
	};


	function addSpaceIfLong(text, maxLength = 20) {
		if (text.length < maxLength) {
			return text;
		}

		const segments = text.split('_');

		return segments.map((segment, index) => {
			return (index === Math.ceil(segments.length / 2)) ? `${segment} ` : segment
		}).join('_');
	}


	function statusSwitch(param) {
		switch(param) {
			case 'loading':
				return <CircularProgress size="1.3rem"/>;
			case 'success':
				return <CheckCircleOutlineIcon sx={{ color: 'green' }}/>;
			case 'failed':
				return <ErrorOutlineIcon sx={{ color: 'red' }}/>;
			default:
				return <WarningAmberIcon sx={{ color: 'yellow' }}/>;
		}
	};


	useEffect(() => {
		const fetch = async() => {
			const receivedMetadata = await apiCallsService.getAttachmentsFormMetaData();
			setMetadata(receivedMetadata);

			if (receivedMetadata.facebook.length === 1) {
				setSelectedBusinessCenter(receivedMetadata.facebook[0]);
			}

			if (localStorage.facebookImporterPreset) {
				const { businessCenterId, adAccountId } = JSON.parse(localStorage.facebookImporterPreset);

				const businessCenterPreset = receivedMetadata.facebook.find(bc => bc.businessCenter.id === businessCenterId);

				if (!businessCenterPreset) {
					return;
				}

				setSelectedBusinessCenter(businessCenterPreset);

				const adAccountPreset = businessCenterPreset.adAccounts.find(ad => ad.id === adAccountId);

				if (!adAccountPreset) {
					return;
				}

				setSelectedAdsManager(adAccountPreset);
			}
		}

		fetch();

		return () => {
			store.releaseAllImportProcesses();
		};
	}, []);

	return (
		<>
			<Title title="Download attachments" />
			{errorText && (
				<Alert severity="error" style={{ marginBottom: "10px" }}>
					{errorText}
				</Alert>
			)}
			<Box sx={{ m: 2 }}>
				<TextField
					margin="normal"
					id="task-link"
					label="Asana task link"
					name="task-link"
					onChange={handleInputChange}
					required
					fullWidth
					autoFocus
				/>


				<Grid container spacing={3} sx={{mb: 2}}>

					<Grid item xs={12} md={8} lg={6}>
						<Card variant="outlined">
							<Box sx={{ p: 2 }}>
								<Stack direction="row" justifyContent="space-between" alignItems="center">
									<Typography gutterBottom variant="h5" component="div">
										Facebook Ads manager importer
									</Typography>
								</Stack>
								<Typography color="text.secondary" variant="body2" sx={{ mb: '10px' }}>
									Select Business Manager and Ads Manager to import the attached video files directly into the advertising account.
								</Typography>
								<Box
									sx={{
										backgroundColor: '#f0f7fb',
										borderLeft: 'solid 4px #3498db',
										lineHeight: '18px',
										overflow: 'hidden',
										padding: '12px'
									}}
								>	
									<Typography color="text.secondary" variant="body2">
										Once successfully uploaded, the video will appear in the Ads Manager within a few minutes.
									</Typography>
								</Box>
							</Box>
							
							<Divider />

							<Box sx={{ p: 2 }}>
								{!metadata
									? <CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }}/>
									: <>
										<FormControl fullWidth sx={{ mb: '20px' }}>
											<Autocomplete
												options={metadata.facebook}
												getOptionLabel={(option) => option.businessCenter.originalName}
												value={selectedBusinessCenter}
												onChange={(event, newValue) => setSelectedBusinessCenter(newValue)}
												renderInput={(params) => <TextField {...params} label="Business Center" size="small" />}
											/>
										</FormControl>


										{selectedBusinessCenter && <FormControl fullWidth sx={{ mb: '20px' }} size="small">
											<Autocomplete
												options={selectedBusinessCenter.adAccounts}
												getOptionLabel={(option) => addSpaceIfLong(option.originalName)}
												value={selectedAdsManager}
												onChange={(event, newValue) => setSelectedAdsManager(newValue)}
												renderInput={(params) => <TextField {...params} label="Ads Manager"  size="small" />}
											/>
										</FormControl>}


										<Button sx={{ mb: '20px', width: '30%', minHeight: '36px' }} variant="contained" onClick={() => importToFacebook()} disabled={!selectedBusinessCenter || !selectedAdsManager || !linkIsValid} >
											{isLoading 
												? <CircularProgress size="1.3rem" sx={{ color: 'white' }}/> 
												: <span>Import</span>}
										</Button>

										<Box sx={{ mb: '20px', width: '100%' }}> 
											{store.progress >= 0 && (
												<LinearProgressWithLabel value={store.progress} />
											)}
										</Box>

										<List sx={{ width: '100%' }}>
											{store.importProcesses.map(importProcess => (
												<ListItem
													key={importProcess.id}
													sx={{justifyContent: 'space-between'}}
												>
												{importProcess.name} {statusSwitch(importProcess.status)}
											  </ListItem>
											))}
										</List>
									</>}
							</Box>
						</Card>
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<Card variant="outlined" sx={{ maxWidth: 500 }}>
							<Box sx={{ p: 2 }}>
								<Stack direction="row" justifyContent="space-between" alignItems="center">
									<Typography gutterBottom variant="h5" component="div">
										Download All Videos
									</Typography>
								</Stack>
								<Typography color="text.secondary" variant="body2">
									All video files attached to the ticket in Asana will be archived into a single file and downloaded to your device.
								</Typography>
							</Box>

							<Divider />

							<Box sx={{ p: 2 }}>
								<Button href={link} variant="contained" disabled={!linkIsValid}>
									<span>Save to Device</span>
								</Button>
							</Box>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
});
