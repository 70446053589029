import React, { useEffect, useState } from "react";

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import ExpandMoreLessButton from './ExpandMoreLessButton';
import Link from '@mui/material/Link';

import apiCallsService from '../services/apiCalls.service';

const PERIOD_TYPE = 'country';

export default function TableOffersDataByCountry({affilateId, offerId, country, fromDate, toDate, accountId, trafficSource, countriesMetadata, showDetailedStatistics, filters, offerTimeframe}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);

	const [sortingBy, setSortingBy] = useState('volume');
	const [sortingDirection, setSortingDirection] = useState(false);

	const fetchDayOfWeekSeasonalityData = async () =>{
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getSeasonalityByPeriodData(offerId, PERIOD_TYPE, { affilateId, country, fromDate, toDate, accountId, trafficSource });
			
			if (response.error) {
				setError(response.error);
				setTableData([]);
				setFilteredTableData([]);
			}
			else {
				response.forEach(record => {
					record.volume = record.averageRevenue * record.totalClicks;
				});

				setTableData(response || []);
			}
		} catch (err) {
			setError('Failed to fetch data');
			setTableData([]);
			setFilteredTableData([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDayOfWeekSeasonalityData();
	}, [offerId, fromDate, toDate, accountId, trafficSource]);

	useEffect(() => {
		let result = [...tableData];

		if ((filters.clicks || filters.clicks === 0) && !isNaN(filters.clicks)) {
			const filterValue = +filters.clicks;
			result = result.filter(record => record.totalClicks > filterValue);
		}

		if ((filters.avg || filters.avg === 0) && !isNaN(filters.clicks)) {
			const filterValue = +filters.avg;
			result = result.filter(record => +record.averageRevenue > filterValue);
		}

		if (sortingBy) {
			if (sortingDirection) {
				result = result.sort((a, b) => a[sortingBy] - b[sortingBy])
			}
			else {
				result = result.sort((a, b) => b[sortingBy] - a[sortingBy])
			}
		}

		setFilteredTableData(result);

	}, [tableData, filters.avg, filters.clicks, sortingDirection, sortingBy]);

	const handleSortingBy = attribute => {
		if (attribute === sortingBy) {
			return setSortingDirection(!sortingDirection);
		}

		setSortingBy(attribute);
		setSortingDirection(false);
	};

	const handleCountryName = countryCode => {
		const country = countriesMetadata.find(country => country.code === countryCode);

		return country?.name;
	}

	const getLinkToAnalyseOfferByCountry = countryCode => {
		const linkMap = {
			tonic: `/analyse-tonic-offers?`,
			crossroads: `/analyse-crossroads-offers?`
		}

		const queryParam = `country=${countryCode}&offer=${offerId}&periodId=${offerTimeframe || 'All'}`;

		return linkMap[affilateId] + queryParam;
	}

	const stylesTableHead = attribute => ({
		color: sortingBy === attribute ? 'rgba(0, 0, 0, 1)': 'rgba(0, 0, 0, 0.6)',
		cursor: 'pointer'
	});

	return ( 
		<>
			{loading ? (
				<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }}/>
			) : error ? (
				<Alert severity="error">Error: {error}</Alert>
			) : ( 
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Country</TableCell>
								<TableCell style={stylesTableHead('avg')} onClick={() => handleSortingBy('averageRevenue')}>AVG<ExpandMoreLessButton tableCell='averageRevenue' sortingBy={sortingBy} sortingDirection={sortingDirection}/></TableCell>
								{showDetailedStatistics &&
									<>
										<TableCell style={stylesTableHead('median')} onClick={() => handleSortingBy('median')}>Median <ExpandMoreLessButton tableCell='median' sortingBy={sortingBy} sortingDirection={sortingDirection}/></TableCell>
									</>}
								<TableCell style={stylesTableHead('clicks')} onClick={() => handleSortingBy('totalClicks')}>Clicks <ExpandMoreLessButton tableCell='totalClicks' sortingBy={sortingBy} sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('volume')} onClick={() => handleSortingBy('volume')}>Volume <ExpandMoreLessButton tableCell='volume' sortingBy={sortingBy} sortingDirection={sortingDirection}/></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredTableData.map((record, index) => (
									<TableRow key={index}>
										
										<TableCell>
											<Link href={getLinkToAnalyseOfferByCountry(record.country)} target="_blank">{handleCountryName(record.country)}</Link>
										</TableCell>
										<TableCell>{record.averageRevenue}$</TableCell>
										{showDetailedStatistics &&
											<>
												<TableCell>{record.median}$</TableCell>
											</>}
										<TableCell>{record.totalClicks}</TableCell>
										<TableCell>{(record.volume).toFixed(0)}$</TableCell>
									</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	)
};
