import React, {useEffect, useState, useMemo} from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";

import TableCell from "@mui/material/TableCell";
import ExpandMoreLessButton from "../../components/ExpandMoreLessButton";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import {observer} from "mobx-react-lite";

import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	PolarAreaController,
	DoughnutController,
	RadialLinearScale,
	ArcElement,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';

ChartJS.register(
	LinearScale,
	CategoryScale,
	RadialLinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
	PolarAreaController,
	DoughnutController
);



export const TableCompare =  observer(({keywordsA, keywordsB, mode}) => {
	const loading = keywordsA.loading || keywordsB.loading;
	const [error, setError] = useState('');
	const [table, setTable] = useState([]);

	const [sortingBy, setSortingBy] = useState('volume');
	const [sortingDirection, setSortingDirection] = useState(false);
	const [sortingColumn, setSortingColumn] = useState('a');
	const [sortingHash, setSortingHash] = useState('volume_a');

	const [commonKeywordsAvgPriceColumnA, setCommonKeywordsAvgPriceColumnA] = useState(null);
	const [commonKeywordsAvgPriceColumnB, setCommonKeywordsAvgPriceColumnB] = useState(null);

	const stylesTableHead = attribute => ({
		color: sortingBy === attribute ? 'rgba(0, 0, 0, 1)': 'rgba(0, 0, 0, 0.6)',
		cursor: 'pointer'
	});


	const handleSortingBy = (attribute, columnId) => {
		const newSortingHash = `${attribute}_${columnId}`;
		const currentSortingHash = `${sortingBy}_${sortingColumn}`;

		if (newSortingHash === currentSortingHash) {
			return setSortingDirection(!sortingDirection);
		}

		setSortingBy(attribute);
		setSortingDirection(false);
		setSortingColumn(columnId);
		setSortingHash(newSortingHash);
	};

	const sortTable = keywords => {
		const propByColumnId= {
			'a': 'keywordA',
			'b': 'keywordB',
			'relative': 'diffRelative'
		};

		const sorted = keywords.sort((row1, row2) => {
			return row1[propByColumnId[sortingColumn]][sortingBy] - row2[propByColumnId[sortingColumn]][sortingBy]
		});


		return sortingDirection ? sorted : sorted.reverse();
	}

	useEffect(() => {
		if (!keywordsA.data || !keywordsB.data) {
			return;
		}


		if (mode === 'inner') {
			const keywordsBMap = new Map();

			keywordsB.data.forEach(keyword => {
				keywordsBMap.set(keyword.offer, keyword);
			});

			const mergedKeywords = [];

			keywordsA.data.forEach(keywordA => {
				const keywordB = keywordsBMap.get(keywordA.offer);

				if (!keywordB) {
					return;
				}

				const diffRelative = {
					clicks: (keywordB.clicks - keywordA.clicks) / keywordA.clicks,
					avg: (keywordB.avg - keywordA.avg) / keywordA.avg,
					volume: (keywordB.volume - keywordA.volume) / keywordA.volume,
				};

				mergedKeywords.push({
					offer: keywordB.offer,
					keywordA,
					keywordB,
					diffRelative
				});
			});

			const totalCommonVolumeA = mergedKeywords.map(row => +row.keywordA.volume).reduce((sum, vol) => {
				return sum + vol;
			}, 0);

			const totalCommonClicksA = mergedKeywords.map(row => +row.keywordA.clicks).reduce((sum, vol) => {
				return sum + vol;
			}, 0);

			const totalCommonVolumeB = mergedKeywords.map(row => +row.keywordB.volume).reduce((sum, vol) => {
				return sum + vol;
			}, 0);

			const totalCommonClicksB = mergedKeywords.map(row => +row.keywordB.clicks).reduce((sum, vol) => {
				return sum + vol;
			}, 0);

			setCommonKeywordsAvgPriceColumnA(totalCommonVolumeA / totalCommonClicksA);
			setCommonKeywordsAvgPriceColumnB(totalCommonVolumeB / totalCommonClicksB);

			const sortedTable = sortTable(mergedKeywords);
			setTable(sortedTable);
		}
	}, [keywordsA.data, keywordsB.data]);

	const sortedTable = useMemo(() => {
		return sortTable(table);
	}, [table, sortingDirection, sortingHash]);

	useEffect(() => {
		setTable(sortedTable);
	}, [sortedTable]);

	return (<>
		{loading ? (
				<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }}/>
		) : error ? (
				<Alert severity="error">Error: {error}</Alert>
		) : (
				<>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell rowSpan={2}>Keyword</TableCell>
								<TableCell colSpan={4} style={stylesTableHead('avg')}>AVG</TableCell>
								<TableCell colSpan={4} style={stylesTableHead('clicks')}>Clicks</TableCell>
								<TableCell colSpan={4} style={stylesTableHead('volume')}>Volume</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={stylesTableHead('avg_a')} onClick={() => handleSortingBy('avg', 'a')}>Left <ExpandMoreLessButton enabled={sortingHash === 'avg_a'} sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('avg_b')} onClick={() => handleSortingBy('avg', 'b')}>Right <ExpandMoreLessButton enabled={sortingHash === 'avg_b'} sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('avg_relative')} onClick={() => handleSortingBy('avg', 'relative')}>% <ExpandMoreLessButton enabled={sortingHash === 'avg_relative'} sortingDirection={sortingDirection}/></TableCell>

								<TableCell style={stylesTableHead('clicks_a')} onClick={() => handleSortingBy('clicks', 'a')}>Left <ExpandMoreLessButton enabled={sortingHash === 'clicks_a'} sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('clicks_b')} onClick={() => handleSortingBy('clicks', 'b')}>Right <ExpandMoreLessButton enabled={sortingHash === 'clicks_b'} sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('clicks_relative')} onClick={() => handleSortingBy('clicks', 'relative')}>% <ExpandMoreLessButton enabled={sortingHash === 'clicks_relative'} sortingDirection={sortingDirection}/></TableCell>

								<TableCell style={stylesTableHead('volume_a')} onClick={() => handleSortingBy('volume', 'a')}>Left <ExpandMoreLessButton enabled={sortingHash === 'volume_a'}  sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('volume_b')} onClick={() => handleSortingBy('volume', 'b')}>Right <ExpandMoreLessButton enabled={sortingHash === 'volume_b'}  sortingDirection={sortingDirection}/></TableCell>
								<TableCell style={stylesTableHead('volume_relative')} onClick={() => handleSortingBy('volume', 'relative')}>% <ExpandMoreLessButton enabled={sortingHash === 'volume_relative'} sortingDirection={sortingDirection}/></TableCell>


							</TableRow>
						</TableHead>
						<TableBody>
							{table.map((record, index) => (
								<TableRow key={index}>
									<TableCell>
										<Link href="#">
											{record.offer}
										</Link>
									</TableCell>
									<TableCell>{record.keywordA.avg}$</TableCell>
									<TableCell>{record.keywordB.avg}$</TableCell>
									<TableCell>{(record.diffRelative.avg * 100).toFixed(0)}%</TableCell>

									<TableCell>{record.keywordA.clicks}</TableCell>
									<TableCell>{record.keywordB.clicks}</TableCell>
									<TableCell>{(record.diffRelative.clicks * 100).toFixed(0)}%</TableCell>

									<TableCell>{(record.keywordA.volume).toFixed(0)}$</TableCell>
									<TableCell>{(record.keywordB.volume).toFixed(0)}$</TableCell>
									<TableCell>{(record.diffRelative.volume * 100).toFixed(0)}%</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</>
		)}
	</>);
})