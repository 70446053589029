import styled from 'styled-components';

export const ButtonAddAccount = styled.button`
	display: inline-block;
	background: none;
	font-weight: 400;
	font-size: 14px;
	text-decoration: underline;
	color: #265b97;
	margin-bottom: 21px;
	cursor: pointer;
	&:hover {
		text-decoration: none;
	}
`;
