import React from 'react';
import styled from 'styled-components';

const RadioLabel = styled.label`
	cursor: pointer;
`;

const RadioInput = styled.input`
	position: absolute;
	z-index: -1;
	margin: 0;
	visibility: hidden;

	&:checked + span {
		background: #e4e4ef;
	}
`;
const CustomRadio = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	min-width: 50px;
	height: 50px;
	padding: 0 6px;
	border: 1px solid #e4e4ef;
	border-radius: 5px;
	color: #303030;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	position: relative;
	transition: all 0.2s ease;
	&:hover {
		background: #e4e4ef;
	}
`;

export const RadioButton = ({
	name,
	value,
	handleChange,
	isChecked,
	text,
	handleClick,
}) => {
	const handleRadioChange = e => {
		const { id } = e.currentTarget;
		handleChange(id);
	};

	return (
		<RadioLabel htmlFor={value}>
			<RadioInput
				type='radio'
				name={name}
				id={value}
				checked={isChecked}
				onChange={handleRadioChange}
				onClick={handleClick}
			/>
			<CustomRadio>{text}</CustomRadio>
		</RadioLabel>
	);
};
