import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Road } from '../routes/Road';
import { ExchangeRate } from '../components/ExchangeRate';

import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const Wrapper = styled.div`
	position: relative;
	display: flex;
	min-height: 100vh;
	width: 100%;
`;

const Main = styled.div`
	width: 80%;
`;
const Navbar = styled.div`
	background: #f4f4f6;
	width: 20%;
`;
const Logo = styled(Link)`
	display: block;
	padding: 10px 10px 10px 31px;
	background: #ffffff;
	height: 59px;
	cursor: pointer;

	& > span {
		font-weight: 700;
		font-size: 32px;
		color: #265b97;
	}
`;
const Block = styled.div`
	display: flex;
	flex-direction: column;
	& > a {
		font-weight: 400;
		font-size: 18px;
		color: #5f5f5f;
		transition: all 0.2s ease;
		padding: 19px 10px 19px 55px;
		border-bottom: 1px solid #e4e4ef;
		&.active {
			color: #265b97;
		}
		&:hover {
			background: #e9e9e9;
		}
	}
`;

const navItems = [
	{ id: 'campaignCreation', label: 'Campaign Creation', to: '/', accessLevels: ['lead', 'buyer'], children: [
		{ label: 'New campaign', to: '/'},
		{ label: 'My profile', to: '/my-profile'},
		{ label: 'Campaign overview', to: '/campaign-overview'},
		{ label: 'Pixels overview', to: '/pixels-overview'},
	]},
	{ id: 'downloadAttachments', label: 'Download attachments', to: '/download-attachments', accessLevels: ['lead', 'buyer', 'helper'] },
	{ id: 'tonicKeywords', label: 'Tonic keywords', to: '/analyse-tonic-offers', accessLevels: ['lead', 'buyer'] },
	{ id: 'domainActiveKeywords', label: 'Domain Active keywords', to: '/analyse-crossroads-offers', accessLevels: ['lead', 'buyer'] },
	{ id: 'keywordsDashboard', label: 'Keywords Dashboard', to: '/keywords-dashboard', accessLevels: ['lead', 'buyer'] },
	{ id: 'compareOffers', label: 'Compare Offers', to: '/compare-offers', accessLevels: ['lead', 'buyer'] },
	{ id: 'offersList', label: 'Offers List', to: '/analyse-offers-list', accessLevels: ['lead', 'buyer'] },
	{ id: 'keywordsList', label: 'Keywords List', to: '/analyse-keywords-list', accessLevels: ['lead', 'buyer'] },

	{ id: 'searchComplainsExternalLink', label: 'Search complaints', to: 'https://www.notion.so/DGTL-Search-Complaints-eaf01d13ee0240d7a1724fe0a319485f', accessLevels: ['lead', 'buyer'], target: '_blank' },
];

export const Home = () => {
	const location = useLocation();
	debugger
	const [expandedItems, setExpandedItems] = useState({});

	const userAccessLevel = localStorage.getItem('userAccessLevel') || '';

	const toggleExpandedItem = id => {
		const updatedExpandedItems = {...expandedItems};

		if (expandedItems[id]) {
			delete updatedExpandedItems[id];
		}
		else {
			updatedExpandedItems[id] = true;
		}

		setExpandedItems(updatedExpandedItems);
	};

	if (['/designer_login', '/login'].includes(location.pathname)) {
		return (
			<Road />
		);
	}

	return (
		<Wrapper>
			<Navbar>
				<Logo to='/'>
					<span>dgtlunion</span>
				</Logo>

				<Block>
					<Block component="nav">
						{navItems.map(({ id, label, to, target, children, accessLevels }) => {
							const isSelected = children && expandedItems[id];

							return !accessLevels.includes(userAccessLevel)
								? <></>
								: <React.Fragment key={label}>
									<NavLink
										to={to}
										target={target || '_self'}
										onClick={() => toggleExpandedItem(id)}
										selected={isSelected}
									>
										<Stack direction="row" spacing={2}>
											<ListItemText primary={label}/>
											{children && <> {isSelected ? <ExpandLess/> : <ExpandMore/>}</>}
										</Stack>
									</NavLink>
									{children && (
										<Collapse in={isSelected} timeout="auto" unmountOnExit>
											<Block component="div">
												{children.map(({label: subLabel, to: childTo}) => (
													<NavLink
														style={{padding: '10px 50px'}}
														key={subLabel}
														sx={{pl: 7}}
														to={childTo}
														selected={location.pathname === childTo}
													>
														<ListItemText style={{ marginLeft: '30px'}} primary={subLabel}/>
													</NavLink>
												))}
											</Block>
										</Collapse>
									)}
								</React.Fragment>
							})}
					</Block>

					<ExchangeRate />
				</Block>
			</Navbar>
			<Main>
				<Road />
			</Main>
		</Wrapper>
	);
};
