import styled from 'styled-components';

export const ErrorWrapper = styled.div`
	min-height: 43px;
`;

export const Error = styled.div`
	background: #ff7e7e;
	margin-left: auto;
	border-radius: 6px;
	padding: 14px 18px;
	text-align: left;
	transform: ${props => (props.errorBlock ? '0' : 'translateX(100vw)')};
	& > span {
		font-weight: 400;
		font-size: 12px;
		color: #000000;
	}
`;
