import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import succesIcon from '../assets/success-icon.svg';
import warnIcon from '../assets/warn-button.svg';
import { RadioButton } from '../components/RadioButton';
import apiCallsService from '../services/apiCalls.service';
import { Title } from '../components/Title';
import { SubmitButton } from '../styledComponents/buttons/SubmitButton';
import { StyledSelect } from '../styledComponents/form/Select';
import { InputLanguage } from '../styledComponents/form/Input';
import { Wrapper } from '../styledComponents/Wrapper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from "@mui/material/Alert";
import { useNavigate } from 'react-router-dom';


const Cover = styled.div`
	display: flex;
	gap: 80px;
	margin-top: 50px;
`;

const Left = styled.div`
	padding-top: 50px;
	width: 60%;
`;

const Form = styled.form``;

const Item = styled.div`
	display: flex;
	align-items: center;
	gap: 23px;
	margin-bottom: 30px;
	&:last-child {
		margin-bottom: 0;
	}
`;

const ItemTitle = styled.h2`
	font-weight: 400;
	font-size: 18px;
	color: #555555;
	width: 203px;
	text-align: right;
`;

const SummaryItemInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	& > span {
		font-weight: 400;
		font-size: 14px;
		color: #555555;
	}
`;

const SummaryTitle = styled.h3`
	font-weight: 400;
	font-size: 18px;
	color: #555555;
	text-align: center;
	margin-bottom: 27px;
`;

const SummaryItem = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	border: 1px solid #efefef;
	border-radius: 5px;
	margin-bottom: 5px;
	padding: 15px 15px 15px 0;
	min-width: 320px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const Block = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	width: calc(100% - 203px);
`;

const Right = styled.div``;

const SummaryItemImg = styled.div`
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Image = styled.img`
	width: 13px;
	height: 13px;
`;

const Input = styled.input`
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	font-weight: 400;
	font-size: 14px;
	padding: 10px;
	color: #000;
	width: calc(100% - 203px);
	transition: all 0.2s ease;
	&::placeholder {
		color: #7d7d7d;
	}
	&:hover,
	&:focus {
		border: 1px solid #265b97;
	}
`;

const SummaryErrorText = styled.span`
	position: absolute;
	left: 40px;
	bottom: 2px;
	font-weight: 400;
	font-size: 8px;
	color: #ca0000;
`;
let summaryItemsModel = [];

export const NewCampaign = () => {
	const [offerOptions, setOfferOptions] = useState([]);
	const [countriesOptions, setCountriesOptions] = useState([]);
	const [pixelOptions, setPixelOptions] = useState([]);
	const [ownLanguageValue, setOwnLanguageValue] = useState('');
	const [languageValue, setLanguageValue] = useState('');
	const [adTitleValue, setAdTitleValue] = useState('');
	const [pixelIdValue, setPixelIdValue] = useState('');
	const [offerValue, setOfferValue] = useState('');
	const [countryValue, setCountryValue] = useState([]);
	const [accountValue, setAccountValue] = useState('');
	const [campaignInfo, setCampaignInfo] = useState([]);
	const [activeLanguage, setActiveLanguage] = useState(false);
	const [showSummary, setShowSummary] = useState(false);
	const [errorModalText, setErrorModalText] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [isFormSending, setIsFormSending] = useState(false);

	const [summaryItems, setSummaryItems] = useState([]);
	const [validationError, setValidationError] = useState(false);

	const navigate = useNavigate();

	const handleAdTitle = value => {
		setAdTitleValue(value);

		setValidationError(value.includes(' '));
	}

	const updateSummaryUI = () => {
		setSummaryItems(
			summaryItemsModel.map((summaryModel, index) => ({
				id: index,
				campaignName: summaryModel.campaignName,
				status: summaryModel.status,
				errorText: summaryModel.errorText,
			}))
		);
	};

	useEffect(() => {
		!ownLanguageValue ? setActiveLanguage(false) : setActiveLanguage(true);

		if (
			!offerValue.value ||
			!countryValue ||
			countryValue.length === 0 ||
			!(languageValue || ownLanguageValue) ||
			!accountValue
		) {
			setShowSummary(false);
			return;
		}

		setShowSummary(true);

		summaryItemsModel = countryValue.map((item, index) => {
			return {
				id: index,
				campaignName: `${offerValue.value} / ${item.code} / ${accountValue} / ${
					languageValue || ownLanguageValue
				}`,
				offer: offerValue.value,
				offerId: offerValue.id,
				country: item.value,
				language: languageValue || ownLanguageValue,
				adAccount: accountValue,
				adTitle: adTitleValue,
				geo: item.code,
				pixelId: pixelIdValue,
				errorText: '',
				status: 'none',
				async submit() {
					this.errorText = null;
					this.status = 'loading';

					updateSummaryUI();

					const response = await apiCallsService.createCampaignDataResultRequest({
						campaignName: this.campaignName,
						offer: this.offer,
						offerId: this.offerId,
						country: this.country,
						language: this.language,
						adAccount: this.adAccount,
						adTitle: this.adTitle,
						pixelId: this.pixelId,
						geo: this.geo,
					});

					if (response.error) {
						this.errorText = response.error;
						this.status = 'error';
						updateSummaryUI();
						return;
					}

					this.status = 'success';
					updateSummaryUI();
				},
			};
		});

		updateSummaryUI();
	}, [
		offerValue.value,
		offerValue.id,
		countryValue,
		languageValue,
		ownLanguageValue,
		accountValue,
		adTitleValue,
		pixelIdValue
	]);

	const handleSubmit = async e => {
		e.preventDefault();
		setIsFormSending(true);

		await Promise.all(
			summaryItemsModel.map(summaryModel => summaryModel.submit())
		);

		setIsFormSending(false);

		if (summaryItemsModel.every(model => !model.errorText)) {
			setTimeout(() => {
				navigate(0);
			}, 3000);
		}
	};

	useEffect(() => {
		const createCompaignInfoResponse = async () => {
			const response = await apiCallsService.createCampaignInfoRequest();

			setIsLoading(false);

			if (response.error) {
				setErrorModalText('Failed to get meta data for this form. Please try again later.');
				return;
			}
			const offersList = response.offersList.map(item => ({
				id: item.id,
				value: item.name,
				label: item.name,
			}));

			const countriesList = response.countriesList.map(item => ({
				id: item.id,
				value: item.name,
				label: item.name,
				code: item.code,
			}));

			const pixelsList = response.pixelsList.map(item => ({
				id: item.id,
				value: item.name,
				label: item.name,
			}));

			setCampaignInfo(response);
			setOfferOptions(offersList);
			setCountriesOptions(countriesList);
			setPixelOptions(pixelsList);
		};

		createCompaignInfoResponse();
	}, []);

	return (
		<>
			<Title title='New campaign' />
			<Wrapper>
				{isLoading && (
					<div className='loading-box'>
						<CircularProgress />
					</div>
				)}

				{errorModalText && <Alert severity="error" style={{marginBottom: '10px'}}>{errorModalText}</Alert>}
				{validationError && <Alert severity="error" style={{marginBottom: '10px'}}>Use "+" instead of spaces for <b>Ad Title</b> field.</Alert>}

				{!isLoading && !errorModalText && (
					<Cover>
						<Left>
							<Form onSubmit={handleSubmit}>
								<Item>
									<ItemTitle>Select Offer</ItemTitle>
									<StyledSelect
										classNamePrefix='select-campaign'
										placeholder='Digital marketing'
										onChange={choice => setOfferValue(choice)}
										options={offerOptions}
									/>
								</Item>
								<Item>
									<ItemTitle>Select Countries</ItemTitle>
									<StyledSelect
										classNamePrefix='select-campaign'
										isMulti
										placeholder='Select Countries'
										onChange={choice => setCountryValue(choice)}
										options={countriesOptions}
									/>
								</Item>
								<Item>
									<ItemTitle>Select Language</ItemTitle>
									<Block>
										{campaignInfo?.languagesList?.map((item, index) => (
											<RadioButton
												key={index}
												name={item}
												id={item}
												value={item}
												text={item}
												handleChange={value => setLanguageValue(value)}
												isChecked={languageValue === item}
												handleClick={() => {
													setActiveLanguage(false);
													setOwnLanguageValue('');
												}}
											/>
										))}
										<InputLanguage
											activeLanguage={activeLanguage}
											placeholder='__'
											type='text'
											maxLength={2}
											value={ownLanguageValue}
											onClick={() => {
												setLanguageValue('');
												setActiveLanguage(true);
											}}
											onChange={e => setOwnLanguageValue(e.target.value)}
										/>
									</Block>
								</Item>
								<Item>
									<ItemTitle>Select add account</ItemTitle>
									<Block>
										{campaignInfo?.adAccountsList?.map((item, index) => (
											<RadioButton
												key={index + 10}
												name={item}
												id={item}
												value={item}
												text={item}
												handleChange={value => setAccountValue(value)}
												isChecked={accountValue === item}
											/>
										))}
									</Block>
								</Item>
								<Item>
									<ItemTitle>Ad title</ItemTitle>
									<Input
										placeholder='Ad title'
										type='text'
										onChange={e => handleAdTitle(e.target.value)}
									/>
								</Item>
								<Item>
									<ItemTitle>Select pixel</ItemTitle>
									<StyledSelect
										classNamePrefix='select-campaign'
										placeholder='Select Tonic ID'
										onChange={choice => setPixelIdValue(choice.id)}
										options={pixelOptions}
									/>
								</Item>
								<SubmitButton
									type='submit'
									disabled={
										!offerValue.value ||
										!countryValue.length > 0 ||
										!accountValue ||
										!adTitleValue ||
										!(languageValue || ownLanguageValue) ||
										isFormSending
									}
								>
									<span>Create new campaigns</span>
								</SubmitButton>
							</Form>
						</Left>
						{showSummary && (
							<Right>
								<SummaryTitle>Summary</SummaryTitle>
								{summaryItems.map(item => (
									<SummaryItem key={item.id}>
										<SummaryItemImg>
											{item.status === 'loading' && (
												<CircularProgress size={13} />
											)}
											{item.status === 'success' && (
												<Image src={succesIcon} alt='success' />
											)}
											{item.status === 'error' && (
												<Image src={warnIcon} alt='error' />
											)}
										</SummaryItemImg>
										<SummaryItemInfo>
											<span>{item.campaignName}</span>
										</SummaryItemInfo>
										<SummaryErrorText>{item.errorText}</SummaryErrorText>
									</SummaryItem>
								))}
							</Right>
						)}
					</Cover>
				)}
			</Wrapper>
		</>
	);
};
